import { SupportedLanguages } from '@rbi-ctg/frontend';
import * as location from 'utils/location';
import { pathname as getPathname } from 'utils/routing';

interface IGetRedirectUrlParams {
  pathname: string;
  selectedLanguage: SupportedLanguages;
  shouldUseLanguageInUrl: boolean;
}

// Get redirect URL for language change redirect, first we get the base redirect pathname without any language
// That results in either new pathname for a static page or remain unchanged in the other case.
// Then we enhance the pathname with the language information based on the ROLLOUT_ENABLE_LANGUAGE_CODE_IN_URLS value to return full URL
export const getRedirectUrl = ({
  pathname,
  shouldUseLanguageInUrl,
  selectedLanguage,
}: IGetRedirectUrlParams) => {
  const redirectPath = shouldUseLanguageInUrl ? getPathname(selectedLanguage)(pathname) : pathname;

  return new URL(`${location.get('origin')}${redirectPath}`);
};
