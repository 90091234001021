export type ConsentGroups = {
  C0001: number;
  C0002: number;
  C0003: number;
  C0004: number;
};

export enum OneTrustGlobalConsentState {
  OPT_OUT = 'Opt-Out',
  OPT_IN = 'Opt-In',
}

export enum BloomreachConsentAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export type ConsentGroupCategory =
  | 'strictly_necessary'
  | 'performance'
  | 'functional'
  | 'targeting';
