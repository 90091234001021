import {
  APPLEPAYLINK_PAYMENT_METHOD_PLACEHOLDER,
  BIZUM_PAYMENT_METHOD_PLACEHOLDER,
  BLIK_PAYMENT_METHOD_PLACEHOLDER,
  CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER,
  CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER,
  PAYMENT_ON_DELIVERY_CARD_PAYMENT_METHOD_PLACEHOLDER,
  PAYPAL_PAYMENT_METHOD_PLACEHOLDER,
  SODEXO_PAYMENT_METHOD_PLACEHOLDER,
  SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER,
  TICKET_RESTAURANT_PAYMENT_METHOD_PLACEHOLDER,
  TICKET_RESTAURANT_VOUCHER_PAYMENT_METHOD_PLACEHOLDER,
  WAYLET_PAYMENT_METHOD_PLACEHOLDER,
} from 'state/payment/constants';
import { IPaymentMethod } from 'state/payment/types';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';

import { IGetAvailablePaymentMethods, ISetupPaymentMethodState } from '../types';

import { getCheckoutPaymentMethodId } from './getCheckoutPaymentMethodId';
import { getPrepaidReloadPaymentMethodId } from './getPrepaidReloadPaymentMethodId';
import { getValidPaymentMethodId } from './getValidPaymentMethodId';
/**
 * Get a valid payment methods list, default, checkout and prepaid payment method ID
 */

const getAvailablePaymentMethods = ({
  accounts,
  applePayCardDetails,
  canUseApplePay,
  canUseGooglePay,
  googlePayCardDetails,
}: IGetAvailablePaymentMethods) => {
  const availablePaymentMethodsWithoutNative = accounts.filter(
    method => !isApplePay(method.fdAccountId) && !isGooglePay(method.fdAccountId)
  );

  // Put Apple/Google Pay on top of the list, after, the prepaid card and finally the CC
  const availablePaymentMethodsWithoutPrepaidOrCash = availablePaymentMethodsWithoutNative.filter(
    method => !method.prepaid && !method.cash
  );

  // Adding Apple Pay if available
  if (canUseApplePay) {
    availablePaymentMethodsWithoutPrepaidOrCash.unshift(applePayCardDetails);
  }
  // Adding Google Pay if available
  if (canUseGooglePay) {
    availablePaymentMethodsWithoutPrepaidOrCash.unshift(googlePayCardDetails);
  }

  return { availablePaymentMethodsWithoutPrepaidOrCash, availablePaymentMethodsWithoutNative };
};

export const getPaymentMethodsState = ({
  paymentMethodId,
  prepaidPaymentMethodId,
  accounts,
  canUseApplePay,
  enableBlik,
  enableCashPayment,
  enablePayPal,
  enableSodexoVoucher,
  enableChequeGourmetVoucher,
  enableTicketRestaurantVoucher,
  enablePayPalPaycomet,
  enableSodexo,
  enableChequeGourmet,
  enableTicketRestaurantCard,
  enableSaveCard,
  applePayCardDetails,
  canUseGooglePay,
  googlePayCardDetails,
  paymentMethodHasBeenInit,
  enablePaymentOnDeliveryCard,
  enableBizum,
  enableWaylet,
  enableApplePayLink,
  // TODO: This parameter/flag is a temporary safeguard, after 1 month in production this should be removed and the final value should always be false.
  enableFirstValidPaymentMethodIdFoundAsDefault = false,
}: ISetupPaymentMethodState) => {
  const { availablePaymentMethodsWithoutPrepaidOrCash, availablePaymentMethodsWithoutNative } =
    getAvailablePaymentMethods({
      accounts,
      applePayCardDetails,
      canUseApplePay,
      canUseGooglePay,
      googlePayCardDetails,
    });

  const isDefaultReloadMethodAGiftCard = (paymentMethod?: IPaymentMethod) =>
    !!paymentMethod?.prepaid;

  const isUsersPaymentMethodGiftCard = isDefaultReloadMethodAGiftCard(
    accounts.find(account => account.accountIdentifier === prepaidPaymentMethodId)
  );

  // Get the default prepaid reload card payment method
  const validDefaultReloadPaymentMethodId = getPrepaidReloadPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: prepaidPaymentMethodId,
    canUseApplePay,
    canUseGooglePay,
    applePayCardDetails,
    googlePayCardDetails,
    isUsersPaymentMethodGiftCard,
  });

  // Get the prepaid reload card payment method
  const validReloadPaymentMethodId = getPrepaidReloadPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: prepaidPaymentMethodId,
    canUseApplePay,
    canUseGooglePay,
    applePayCardDetails,
    googlePayCardDetails,
  });

  const prepaidCard = accounts.find(method => !!method.prepaid);
  const cash = accounts.find(method => !!method.cash);

  const allPaymentList: IPaymentMethod[] = [];
  const availablePaymentMethodList = availablePaymentMethodsWithoutPrepaidOrCash.filter(method => {
    allPaymentList.push(method);
    if (!enableSaveCard && method.credit && Object.keys(method.credit).length) {
      return false;
    }

    return true;
  });

  const availablePaymentMethodListDictionary = availablePaymentMethodList.reduce(
    (acc: Record<string, boolean>, method) => {
      if (method.accountIdentifier) {
        acc[method?.accountIdentifier] = true;
      }
      return acc;
    },
    {}
  );

  if (prepaidCard) {
    availablePaymentMethodList.unshift(prepaidCard);
  }

  if (cash) {
    allPaymentList.push(cash);
    if (enableCashPayment) {
      availablePaymentMethodList.push(cash);
    }
  }

  if (
    !availablePaymentMethodListDictionary[PAYPAL_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? '']
  ) {
    allPaymentList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
    if (enablePayPal) {
      availablePaymentMethodList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
    if (enableSodexoVoucher) {
      availablePaymentMethodList.push(SODEXO_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
    if (enableChequeGourmetVoucher) {
      availablePaymentMethodList.push(CHEQUE_GOURMET_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      TICKET_RESTAURANT_VOUCHER_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(TICKET_RESTAURANT_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
    if (enableTicketRestaurantVoucher) {
      availablePaymentMethodList.push(TICKET_RESTAURANT_VOUCHER_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[BLIK_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? '']
  ) {
    allPaymentList.push(BLIK_PAYMENT_METHOD_PLACEHOLDER);
    if (enableBlik) {
      availablePaymentMethodList.push(BLIK_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[PAYPAL_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? '']
  ) {
    allPaymentList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
    if (enablePayPalPaycomet) {
      availablePaymentMethodList.push(PAYPAL_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[SODEXO_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? '']
  ) {
    allPaymentList.push(SODEXO_PAYMENT_METHOD_PLACEHOLDER);
    if (enableSodexo) {
      availablePaymentMethodList.push(SODEXO_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER);
    if (enableChequeGourmet) {
      availablePaymentMethodList.push(CHEQUE_GOURMET_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      TICKET_RESTAURANT_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(TICKET_RESTAURANT_PAYMENT_METHOD_PLACEHOLDER);
    if (enableTicketRestaurantCard) {
      availablePaymentMethodList.push(TICKET_RESTAURANT_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      PAYMENT_ON_DELIVERY_CARD_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(PAYMENT_ON_DELIVERY_CARD_PAYMENT_METHOD_PLACEHOLDER);
    if (enablePaymentOnDeliveryCard) {
      availablePaymentMethodList.push(PAYMENT_ON_DELIVERY_CARD_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[BIZUM_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? '']
  ) {
    allPaymentList.push(BIZUM_PAYMENT_METHOD_PLACEHOLDER);
    if (enableBizum) {
      availablePaymentMethodList.push(BIZUM_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[WAYLET_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? '']
  ) {
    allPaymentList.push(WAYLET_PAYMENT_METHOD_PLACEHOLDER);
    if (enableWaylet) {
      availablePaymentMethodList.push(WAYLET_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  if (
    !availablePaymentMethodListDictionary[
      APPLEPAYLINK_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier ?? ''
    ]
  ) {
    allPaymentList.push(APPLEPAYLINK_PAYMENT_METHOD_PLACEHOLDER);
    if (enableApplePayLink) {
      availablePaymentMethodList.push(APPLEPAYLINK_PAYMENT_METHOD_PLACEHOLDER);
    }
  }

  // Get the default payment method
  const validDefaultPaymentMethodId = getValidPaymentMethodId({
    canUseApplePay,
    canUseGooglePay,
    paymentMethodId,
    paymentMethods: availablePaymentMethodList,
    // TODO: This parameter/flag is a temporary safeguard, after 1 month in production this should be removed and the final value should always be false.
    returnFirstValid: enableFirstValidPaymentMethodIdFoundAsDefault,
  });

  // Get the checkout payment method
  const validCheckoutPaymentMethodId = getCheckoutPaymentMethodId({
    paymentMethodHasBeenInit,
    accounts: [...availablePaymentMethodsWithoutNative],
    usersPaymentMethodId: paymentMethodId,
    canUseApplePay,
    applePayCardDetails,
    canUseGooglePay,
    googlePayCardDetails,
  });

  return {
    availablePaymentMethodList,
    validDefaultPaymentMethodId,
    validCheckoutPaymentMethodId,
    validReloadPaymentMethodId,
    validDefaultReloadPaymentMethodId,
    allPaymentList,
  };
};
