/**
 * The following enum and utility types give us strongly-typed
 * LaunchDarkly flag values. When adding a new flag, two steps
 * must be taken to ensure the correct type is associated with the flag
 */

/**
 * First update this enum to include the flag name
 * Note: the flag name should appear exactly as is it is in the
 * Launch Darkly dashboard
 */
export enum LaunchDarklyFlag {
  AB_TESTING_HOME_PAGE = 'ab-testing-home-page',
  // Flag for AB Testing of Navbar //
  AB_TESTING_FEATURE_NAVIGATION = 'ab-testing-feature-navigation',
  AUTO_FILL_TEST_CARD = 'autoFillTestCard',
  CHEF_CART_UPSELL_ITEM_COUNT = 'chefUpsellItemCount',
  CHEF_RECOMMENDATION_ENGINE_2 = 'chef-recommendation-engine-2',
  COMMUNICATIONS_SCREEN_NEW_DESIGN = 'communications-screen-new-design',
  CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH = 'curbsideVehicleDescriptionMaxLength',
  DATADOG_LOG_SAMPLE_RATE = 'datadogLogSampleRate',
  DATADOG_LOG_SAMPLE_RATE_MPARTICLE = 'datadogLogSampleRateMParticle',
  DATADOG_RUM_SAMPLE_RATE = 'datadogRumSampleRate',
  DATADOG_RUM_REPLAY_SAMPLE_RATE = 'datadogRumReplaySampleRate',
  DEFAULT_SERVICE_MODE_IN_STORE = 'defaultServiceModeInStore',
  DELIVERY_BANNER_POLLING = 'deliveryBannerPolling',
  DELIVERY_RADIUS_IN_MILES = 'deliveryRadiusInMiles',
  DISABLE_DINE_IN = 'disableDineIn',
  DISABLE_DRIVE_THRU = 'disableDriveThru',
  DISABLE_LOCALIZATION = 'disableLocalization',
  DISABLE_TAKE_OUT = 'disableTakeOut',
  DISABLE_COOKIE_MPARTICLE_OPT_OUT = 'disable-cookie-optout-for-mparticle',
  DISCOUNTED_DELIVERY_THRESHOLD_CENTS = 'discounted-delivery-threshold-cents',
  /** temporary flag: once menu redesign and menu service get integrated, we should remove all usages of this flag */
  DISPLAY_NUTRITION_WITH_MODIFIERS_FROM_SANITY = 'displayNutritionWithModifiersFromSanity',
  DEPRECATE_CHEF = 'deprecate-chef',
  ENABLE_ACCOUNT_DELETE_PAGE = 'enableAccountDeletePage',
  ENABLE_ACCOUNT_IN_FOOTER = 'enable-account-in-footer',
  ENABLE_ADD_EXTRAS = 'enable-add-extras',
  ENABLE_ADYEN_IDEAL_PAYMENT = 'enableAdyenIdealPayment',
  ENABLE_ALLERGENS_DISCLAIMER = 'enableAllergensDisclaimer',
  ENABLE_ALPHA_BETA_STORE_ORDERING = 'enable-alpha-beta-store-ordering',
  ENABLE_AMENITIES_SECTION = 'enable-amenities-section',
  ENABLE_APP_FLOW_UPDATE = 'enable-app-flow-update',
  ENABLE_APPLE_PAY = 'enableApplePay',
  ENABLE_AUTO_LINK_REWARDS_CARD = 'enable-auto-link-rewards-card',
  ENABLE_AUTOMATIC_LOCATION_SHARE_AND_KEYBOARD_CLOSE = 'enable-automatic-location-share-and-keyboard-close',
  ENABLE_AUTO_SELECT_RESTAURANT = 'enableAutoSelectRestaurant',
  ENABLE_BEEPER_MODAL = 'enable-beeper-modal',
  ENABLE_SIGNOUT_ON_INVALID_TOKEN = 'enable-signout-on-invalid-token',
  ENABLE_CLOSING_TIME_DIALOG = 'enable-closing-time-dialog',
  ENABLE_BACKEND_COMMIT_ORDER = 'enable-backend-commit-order',
  ENABLE_BLIK_PAYMENT = 'enable-blik-payment',
  ENABLE_BONUS_POINTS_REWARDS_LOGO = 'enableBonusPointsRewardsLogo',
  ENABLE_BOTTOM_HERO_BUTTON = 'enable-bottom-hero-button',
  ENABLE_BOTTOM_SERVICE_MODE = 'enableBottomServiceMode',
  ENABLE_CALORIES_CALCULATOR = 'enable-calories-calculator',
  ENABLE_CASH_PAYMENT = 'enable-cash-payment',
  ENABLE_CATERING = 'enableCatering',
  ENABLE_CATERING_DELIVERY = 'enableCateringDelivery',
  CHECKOUTDOTCOM_RISK = 'checkoutdotcom-risk',
  ENABLE_CHECKOUT_UPSELL_ITEMS_2 = 'enable-checkout-upsell-items-2',
  ENABLE_CLAIM_LOYALTY_POINTS = 'enable-claim-loyalty-points',
  ENABLE_CLEAR_STORE_AFTER_ORDER = 'enable-clear-store-after-order',
  ENABLE_COMMUNICATION_PREFERENCES = 'enableCommunicationPreferences',
  ENABLE_CONSOLIDATED_DELIVERY_RESTAURANT_BACKEND_LOGIC = 'enable-consolidated-delivery-restaurant-backend-logic',
  ENABLE_COOKIE_BANNER = 'enableCookieBanner',
  ENABLE_COOKIE_VERSIONING = 'enableCookieVersioning',
  ENABLE_CURBSIDE = 'enableCurbside',
  ENABLE_CUSTOM_BIRTHDAY_INPUT = 'enableCustomBirthdayInput',
  ENABLE_CUSTOMER_CAN_EDIT_OWN_PHONE_NUMBER = 'enable-customer-can-edit-own-phone-number',
  ENABLE_DELIVERY = 'delivery',
  ENABLE_DELIVERY_CANCELLATION_POLICY = 'enableDeliveryCancellationPolicy',
  ENABLE_DELIVERY_ONLY = 'enable-delivery-only',
  ENABLE_DELIVERY_ORDER_TIMED_FIRE = 'enable-delivery-order-timed-fire',
  ENABLE_DELIVERY_WATCH_DRIVER_LOCATION = 'enableDeliveryWatchDriverLocation',
  /**
   * Enable checkout outside opening hours, only for delivery
   */
  ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS = 'enable-delivery-checkout-outside-opening-hours',
  /**
   * Whether or not to require address line 2 in the delivery details form
   * - Some markets, like BK DE, require this to identify the customer
   */
  ENABLE_DELIVERY_DETAILS_FORM_REQUIRE_ADDRESS_2 = 'enable-delivery-details-form-require-address-2',
  ENABLE_DESKTOP_SIDE_NAVIGATION = 'enable-desktop-side-navigation',
  ENABLE_DIAGNOSTIC_TOOLS = 'enableDiagnosticTools',
  ENABLE_DISCOUNTS_ON_ORDER_RECEIPT = 'enable-discounts-on-order-receipt',
  /**
   * On the menu page, display the products under each category instead of only the categories. This is part of the TRX-1391 JIRA ticket and its goal is to increase sales.
   */
  ENABLE_DIRECT_ACCESS_TO_MENU = 'enable-direct-access-to-menu',
  ENABLE_DOUBLE_VAULTING_FROM_PAYMENT_METHODS = 'enableDoubleVaultingFromPaymentMethods',
  ENABLE_DRIVE_THRU_TIMED_FIRE = 'enable-timed-fire-for-drive-thru-web',
  ENABLE_EDIT_CART = 'enable-edit-cart',
  ENABLE_EU_NUTRITION_DISPLAY = 'nutritionEuDisplay',
  ENABLE_FAVORITE_STORES = 'enableFavoriteStores',
  ENABLE_FAVORITES = 'enable-favorites',
  ENABLE_FAVORITES_DISPLAY_CALORIES = 'enable-favorites-display-calories',
  ENABLE_FEATURE_HOME_PAGE = 'enableFeatureHomePage',
  ENABLE_FEATURE_NUTRITION_INTEGRATION = 'enable-feature-nutrition-integration',
  ENABLE_FEES_ON_ORDER_RECEIPT = 'enable-fees-on-order-receipt',
  ENABLE_FIND_RESTAURANT_LINK = 'enable-find-restaurant-link',
  ENABLE_FIRST_VALID_PAYMENT_METHOD_ID_FOUND_AS_DEFAULT = 'enable-first-valid-payment-method-id-found-as-default',
  ENABLE_FIXED_ADD_TO_ORDER_AND_QUANTITY_BUTTON = 'enable-fixed-add-to-order-and-quantity-button',
  ENABLE_FOOTER_CART_PAGE = 'enable-footer-cart-page',
  ENABLE_FORCED_NATIVE_APP_UPDATE = 'enable-forced-native-app-update',
  ENABLE_FORMATTED_CALORIES_FOR_DEFAULT = 'enableFormattedCaloriesForDefault',
  ENABLE_FORTER_FRAUD = 'enable-forter-fraud',
  ENABLE_FREE_DELIVERY_CHECKOUT_PROGRESS_BAR = 'enableFreeDeliveryCheckoutProgressBar',
  ENABLE_GIFT_CARD = 'gift-card-payment-method',
  ENABLE_GLOBAL_OFFERS_COOLDOWN = 'enable-global-offers-cooldown',
  ENABLE_GOOGLE_PAY = 'enableGooglePay',
  ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE = 'enableGuestAndMultiStepSupportForm',
  ENABLE_GUEST_CHECKOUT = 'enable-guest-checkout',
  ENABLE_HAMBURGER_MENU_ON_MOBILE = 'enable-hamburger-menu-on-mobile',
  ENABLE_HIDE_MODIFIER_MULTIPLIERS = 'enable-hide-modifier-multipliers',
  ENABLE_HIDE_LOYALTY_BANNER_WELCOME_MESSAGE = 'enableHideLoyaltyBannerWelcomeMessage',
  /**
   * If true, hide the "Order Here" button in the store info modal
   * - Used with store card buttons to effectively disable pickup service mode
   */
  ENABLE_HIDE_STORE_MODAL_ORDER_HERE_BUTTON = 'enable-hide-store-modal-order-here-button',
  /**
   * If true, hide the tax line in the cost breakdown wherever shown (checkout, receipt, etc)
   * Applicable in any market where we use VAT instead of sales tax, like BK DE
   */
  ENABLE_HIDE_TAX_LINE = 'enable-hide-tax-line',
  /**
   * If true, hide the close button in the modal when payment is processing
   */
  ENABLE_HIDE_CLOSE_MODAL_WHEN_PAYMENT_PROCESSING = 'enable-hide-close-modal-when-payment-processing',
  /**
   * If true, hide the subtotal line in the cost breakdown (checkout, recent orders)
   */
  ENABLE_HIDE_SUBTOTAL_LINE = 'enable-hide-subtotal-line',
  ENABLE_HOME_PAGE_RECENT_ITEMS = 'enableHomePageRecentItems',
  ENABLE_IM_CLOSE_DISPLAY = 'enableImCloseDisplay',
  ENABLE_STATIC_IN_STORE_IDENTIFICATION = 'enable-static-in-store-identification',
  ENABLE_INTERNAL_DIAGNOSTIC_TOOLS = 'enableInternalDiagnosticTools',
  ENABLE_IMPROVED_STORE_SELECTION_OPTIONS = 'enable-improved-store-selection-options',
  ENABLE_LINK_PHYSICAL_CARD = 'enable-link-physical-card',
  ENABLE_LIST_RESTAURANTS_IN_PARALLEL = 'enable-list-restaurants-in-parallel',
  ENABLE_LOCATOR_CLEAR_STORE_AND_SM_BUTTON = 'enable-locator-clear-store-and-sm-button',
  ENABLE_LOCK_ON_REWARD_IMAGE = 'enable-lock-on-reward-image',
  ENABLE_CONDENSED_REWARD_LAYOUT = 'enable-condensed-reward-layout',
  ENABLE_LOYALTY_OPT_IN_MODAL = 'enable-loyalty-opt-in-modal',
  ENABLE_LOYALTY_POINTS_IN_HEADER = 'enable-loyalty-points-in-header',
  ENABLE_LOYALTY_QR_CODE_IN_MY_CODE = 'enable-qr-code-in-my-code-page',
  ENABLE_LOYALTY_SURPRISE_OFFERS = 'enable-loyalty-surprise-offers',
  ENABLE_MENU_LIST_UI = 'enable-menu-list-ui',
  ENABLE_MENU_UPSELL_ITEMS = 'enable-menu-upsell-items',
  ENABLE_METER_ON_REWARDS = 'enable-meter-on-rewards',
  ENABLE_LOGIN_FROM_US_IF_USER_NOT_IN_EU = 'enable-migration-from-us-if-user-is-not-in-eu',
  ENABLE_MOBILE_COOKIE_BANNER = 'enable-mobile-cookie-banner',
  ENABLE_MOBILE_FOOTER = 'enable-mobile-footer',
  ENABLE_MORE_INFO_OPTIONAL_OFFER_DISCLAIMER = 'enableMoreInfoOptionalOfferDisclaimer',
  ENABLE_MOVE_SIGNUP_AFTER_CART = 'enable-move-signup-after-cart',
  ENABLE_COGNITOID_QR_CODE = 'enable-cognitoid-qr-code',
  ENABLE_MY_CODE_PAYMENT_METHOD = 'enable-my-code-payment-method',
  ENABLE_NATIVE_MOBILE_ACCOUNT = 'enable-native-mobile-account',
  ENABLE_NEW_WIZARD_ISSUE_COLLECTOR = 'new-wizard-issue-collector',
  ENABLE_NEW_PHONE_NUMBER_FIELD = 'enableNewPhoneNumberField',
  ENABLE_NEW_SERVICE_MODE_FLOW = 'enableNewServiceModeFlow',
  /** temporary flag: once proper phone validation is implemented for all regions, this can be removed. */
  ENABLE_NUMERIC_PHONE_VALIDATION = 'enable-numeric-phone-validation',
  ENABLE_NUTRITION_DECIMALS = 'enableNutritionDecimals',
  ENABLE_NUTRITION_EXPLORER = 'enableNutritionExplorer',
  ENABLE_NUTRITION_EXPLORER_ALLERGENS = 'enable-nutrition-explorer-allergens',
  ENABLE_OFFER_BADGES = 'enableOfferBadges',
  ENABLE_OFFER_REDEMPTION_METHODS = 'enable-offer-redemption-methods',
  ENABLE_OFFERS = 'enableOffers',
  ENABLE_OFFERS_EDIT_CART = 'enable-offers-edit-cart',
  ENABLE_OFFERS_MOBILE = 'enableOffersMobile',
  ENABLE_OFFERS_REFRESH = 'enableOffersRefresh',
  ENABLE_OFFERS_UNDER_LOYALTY_TAB = 'offers-under-loyalty-tab',
  ENABLE_ONE_TRUST_COOKIE_CONSENT_BANNER = 'enable-one-trust-cookie-consent-banner',
  ENABLE_ORDER_LINK_NAV = 'enableOrderLinkNav',
  ENABLE_ORDER_NUMBER_GENERATION = 'enable-order-number-generation',
  ENABLE_ORDER_SUPPORT = 'enableOrderSupport',
  ENABLE_ORDER_TIMED_FIRE = 'enableOrderTimedFire',
  ENABLE_ORDER_TIMED_FIRE_NCR = 'enableOrderTimedFireNCR',
  ENABLE_ORDER_TIMED_FIRE_SICOM = 'enableOrderTimedFireSICOM',
  ENABLE_ORDER_TIMED_FIRE_QST = 'enableOrderTimedFireQST',
  ENABLE_ORDER_TIMED_FIRE_RPOS = 'enable-rpos-order-timed-fire',
  ENABLE_ORDERING = 'enableOrdering',
  ENABLE_PAYBACK = 'enable-payback',
  ENABLE_PAYMENT_ERROR_CONTINUE = 'enable-payment-error-continue',
  ENABLE_PAYPAL_PAYMENTS = 'enable-paypal-payments',
  ENABLE_PERSONALIZED_OFFERS_TEMPLATE = 'fe-personalized-offers-template',
  ENABLE_PLU_OFFER_QR_CODE = 'enable-plu-offer-qr-code',
  ENABLE_PREMIUM_COMBO_SLOTS = 'enable-premium-combo-slots',
  ENABLE_PRICE_AND_CALORIES_IN_MENU = 'enable-price-and-calories-in-menu',
  ENABLE_PRODUCT_IMAGE_DISCLAIMER = 'enable-product-image-disclaimer',
  ENABLE_PRODUCT_UPSELL_SIMPLIFIED = 'enable-product-upsell-simplified',
  ENABLE_PROMO_CODE_AT_CHECKOUT = 'enablePromoCodeAtCheckout',
  ENABLE_LOYALTY_PROMO_CODES = 'enable-loyalty-promo-codes',
  ENABLE_RADAR_TRACKING = 'enableRadarTrackingOnNewSessions',
  ENABLE_RECENT_ITEMS_ADD_TO_CART = 'enable-recent-items-add-to-cart',
  ENABLE_RECENT_ITEMS_IN_MENU = 'enable-recent-items-in-menu',
  ENABLE_RECENT_ITEMS_WITH_MODIFIERS = 'enableRecentItemsWithModifiers',
  ENABLE_RECENT_ADDRESSES = 'enable-recent-addresses',
  ENABLE_SAVED_ADDRESSES_IMPROVEMENTS = 'enable-saved-addresses-improvements',
  ENABLE_RECENTLY_ORDERED_ITEMS = 'enableRecentlyOrderedItems',
  ENABLE_REORDER = 'enable-reorder',
  ENABLE_REQUEST_MY_INFORMATION = 'enableRequestMyInformation',
  ENABLE_RETURN_ALL_REWARDS = 'enable-return-all-rewards',
  ENABLE_REWARD_CARD_DEACTIVATION = 'enable-reward-card-deactivation',
  ENABLE_REWARD_PAGE_STAND_ALONE = 'enable-reward-page-stand-alone',
  ENABLE_SANITY_CDN = 'enableSanityCdn',
  ENABLE_SANITY_CONTROLLED_MOBILE_NAV = 'enableSanityControlledMobileNav',
  ENABLE_SANITY_FOOTER = 'enableSanityFooter',
  ENABLE_SANITY_WEB_NAVIGATION = 'enableSanityWebNavigation',
  ENABLE_SEND_SAME_OTP_CODE_ONCE = 'send-same-otp-code-once',
  ENABLE_SERVICE_MODE_CART_SELECTION = 'enableServiceModeCartSelection',
  ENABLE_SHOW_ADDRESS2_BEFORE_CITY = 'enable-show-address2-before-city',
  ENABLE_SIDE_BY_SIDE_HERO_BUTTONS = 'enable-side-by-side-hero-buttons',
  ENABLE_SIGN_UP = 'enableSignUp',
  ENABLE_SIGNUP_CART_BUTTON_TEXT = 'enable-signup-cart-button-text',
  ENABLE_SIGNUP_COMMUNICATIONS_PRESELECTION = 'enable-signup-communications-preselection',
  EXPAND_OPTIONAL_INFORMATION_IN_SIGN_UP = 'expand-optional-information-in-sign-up-form',
  ENABLE_SORT_OFFERS_FOR_SERVICE_MODE = 'enable-sort-offers-for-service-mode',
  ENABLE_SORTED_STORE_LOCATION_LIST = 'enable-sorted-store-location-list',
  ENABLE_STATIC_MENU = 'enableStaticMenu',
  ENABLE_STATIC_MENU_ALLERGENS = 'enable-static-menu-allergens',
  ENABLE_STORE_CONFIRMATION_MODAL = 'enableStoreConfirmationModal',
  ENABLE_STORE_SELECTION_2_0 = 'enable-store-selection-2',
  ENABLE_TABBED_MENU_BAR = 'enable-tabbed-menu-bar',
  ENABLE_TABBED_MENU_BAR_FAVORITES = 'enable-tabbed-menu-bar-fav',
  ENABLE_TABBED_MENU_BAR_RECENTS = 'enable-tabbed-menu-bar-recents',
  ENABLE_TOP_SERVICE_MODE = 'enable-top-service-mode',
  ENABLE_TWINT = 'enable-twint-payment',
  ENABLE_UNVERIFIED_SAVED_PAYMENTS = 'enable-unverified-saved-payments',
  ENABLE_USER_BLOCKING = 'enable-user-blocking',
  /** Shows modal with user preference questions, BK-DE initially */
  ENABLE_USER_PREFERENCE_QUESTIONNAIRE = 'enable-user-preference-questionnaire',
  ENABLE_USER_SAVED_DELIVERY_ADDRESS_PHONE = 'enableUserSavedDeliveryAddressPhone',
  ENABLE_WAIT_TIME_MANAGEMENT = 'enable-wait-time-management',
  ENABLE_ZERO_BOUNCE_ERROR_MODAL = 'enable-zero-bounce-error-modal',
  FIRE_ORDER_AHEAD = 'fireOrderAhead',
  FORCE_HOMEPAGE_AUTH = 'force-homepage-auth',
  FORCE_RESTAURANT_SELECTION_FOR_REWARDS = 'force-restaurant-selection-for-rewards',
  GOOGLE_PAY_ALLOWED_PAYMENT_METHODS = 'google-pay-allowed-payment-methods',
  HIDE_ITEMS_FROM_NUTRITION_INFO = 'hide-items-from-nutrition-info',
  HIDE_SERVICE_MODE = 'hideServiceMode',
  HIDE_SERVICE_MODE_OPTION = 'hide-service-mode-option',
  HIDE_TIP_AMOUNT_CHECKOUT = 'hide-tip-amount-checkout',
  /** Hide all calories outside of the nutrition explorer */
  HIDE_UI_CALORIES = 'hide-ui-calories',
  LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS = 'loyalty-in-restaurant-status-poll-interval-ms',
  MOBILE_CART_UPSELL_LOCATION = 'mobileCartUpsellLocation',
  MY_ACCOUNT_FIELDS_VARIATIONS = 'my-account-fields-variations',
  ORDER_LOCATOR_TABS = 'order-locator-tabs',
  ORDER_PRICE_REFRESH_INTERVAL_MS = 'orderPriceRefreshIntervalMs',
  OVERRIDE_CHECKOUT_CATERING_LIMIT = 'CheckoutCateringLimitOverrideCents',
  OVERRIDE_CHECKOUT_CATERING_MINIMUM = 'checkoutCateringMinimumOverrideCents',
  OVERRIDE_CHECKOUT_DELIVERY_MINIMUM = 'checkoutDeliveryMinimumOverrideCents',
  OVERRIDE_CHECKOUT_LIMIT = 'CheckoutLimitOverrideCents',
  PAYMENT_FIELD_VARIATIONS = 'payment-fields-variations',
  SUPPORTED_CARD_BRANDS_VARIATIONS = 'supported-card-brands-variations',
  RADIUS_FOR_STORE_LOCATOR = 'radius-for-store-locator',
  RESET_CART_RESET_STORE_TIMEOUT = 'resetStoreResetCartTimeout',
  RESET_CART_TIMEOUT_HRS = 'reset-cart-timeout-hrs',
  SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT = 'send-postal-code-only-for-first-data-payment',
  SHOW_INCREASED_DELIVERY_FEES_MODAL = 'show-increased-delivery-fees-modal',
  SHOW_LOYALTY_BANNER_V1 = 'show-loyalty-banner-v1',
  SHOW_LOYALTY_BANNER_V2 = 'show-loyalty-banner-v2',
  SHOW_LOYALTY_BANNER_V2_IN_HOME = 'show-loyalty-banner-v2-in-home',
  SHOW_ORDERING_ISSUE = 'show-ordering-issue-info',
  SIGN_UP_FIELDS_VARIATIONS = 'sign-up-fields-variations',
  SKIP_LOYALTY_STORE_VALIDATION = 'skip-loyalty-store-validation',
  STORE_CARD_BUTTON_OPTIONS = 'store-card-button-options',
  TEMP_ENABLE_CAROUSEL_COMPONENT_UPDATE = 'temp-enable-carousel-component-update',
  TIERED_CATERING_DELIVERY_FEES = 'tiered-catering-delivery-fees',
  TIERED_DELIVERY_FEES = 'tiered-delivery-fees',
  TIMED_FIRE_OPTIONS = 'timed-fire-options',
  TIP_PERCENT_THRESHOLD_CENTS = 'tipPercentThresholdCents',
  TWO_STEP_CHECKOUT = 'two-step-checkout',
  USE_HIGH_VOLUME_SUPPORT_MSG = 'use-high-volume-support-msg',
  ENABLE_PREPAID_PAYMENT_METHODS = 'enable-prepaid-payment-methods',
  ENABLE_PREPAID_DIGITAL_CARD_CREATION = 'enable-prepaid-digital-card-creation',
  ENABLE_SHOW_PICKER_ASPECTS_IN_STATIC_MENU = 'enable-show-picker-aspects-in-static-menu',
  ENABLE_SWITCH_SERVICE_MODE_MODAL = 'enable-switch-service-mode-modal',
  LOYALTY_QR_CODE_SIZE_COLOR_VARIATIONS = 'loyalty-qr-code-size-color-variations',

  // ****** TEST FLAGS ******
  // These flags may not exist in LD but allows us to enable their functionality
  // by using ?rbiFeature.$FLAG=true on the url such as rbiFeature.enable-test-google-pay-web=true
  ENABLE_COOLDOWN_TOAST_INFO = 'enable-cooldown-toast-info',
  ENABLE_MAIN_FAB_BUTTON = 'enable-main-fab-button',
  ENABLE_TEST_APPLE_PAY_WEB = 'enable-test-apple-pay-web',
  ENABLE_TEST_GOOGLE_PAY_WEB = 'enable-test-google-pay-web',
  ORDER_CART_VERSION = 'order-cart-version',

  /*
  Load static Sanity page instead of default loyalty component
  */
  ENABLE_REWARDS_STATIC_PAGE = 'enable-rewards-static-page',
  ENABLE_OFFERS_STATIC_PAGE = 'enable-offers-static-page',

  /*
  Determines what sections are displayed in the /account page
  */
  ENABLE_ACCOUNT_SECTIONS_VARIATIONS = 'enable-account-sections-variations',

  /*
  Validates the user's region is equal to the current region
  */
  ENABLE_ACCOUNT_REGION_VERIFICATION = 'enable-account-region-verification',

  /*
  Handles payment in a PAYMENT_SUCCESSFUL state (i.e. after payment, before order injection).
  */
  ENABLE_ASYNC_PAYMENT = 'enable-async-payment',

  /*
  Enables push notifications at sign up if the user selects marketing emails
  */
  ENABLE_PUSH_NOTIFICATIONS_ON_SIGN_UP = 'enable-push-notifications-on-signup',

  /*
  Enable detailed error list in the cart modal error
  */
  ENABLE_CART_MODAL_ERROR_DETAILED_ERROR_LIST = 'enable-cart-modal-error-detailed-error-list',

  /*
  Enable third party URL redirect IF unavailable delivery modal for BKDE
  */
  ENABLE_THIRD_PARTY_REDIRECT_UNAVAILABLE_DELIVERY_MODAL = 'enable-third-party-redirect-unavailable-delivery-modal',

  /*
  Enable additional store details. Only needed for BK DE
  */
  ENABLE_CHECKOUT_MODAL_FZ_INFORMATION = 'enable-checkout-modal-fz-information',

  /*
  Enable routing for getOrder query
  */
  ENABLE_FULFILLMENT_SERVICE_GET_ORDER = 'enable-fulfillment-service-get-order',

  /*
  Enable routing for priceOrder mutation
  */
  ENABLE_FULFILLMENT_SERVICE_PRICE_ORDER = 'enable-fulfillment-service-price-order',

  /*
  Enable routing for commitOrder mutation
  */
  ENABLE_FULFILLMENT_SERVICE_COMMIT_ORDER = 'enable-fulfillment-service-commit-order',

  /*
  Enable routing for getUserOrders query
  */
  ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS = 'enable-fulfillment-service-get-user-orders',

  /*
  Enable restaurant querying for vrPayment merchantId configuration. Only needed for BK DE
  */
  ENABLE_QUERY_VR_PAYMENT_MERCHANT_ID = 'enable-query-vr-payment-merchant-id',

  SHOW_PRICE_INSTEAD_FREE_IN_CART = 'show-price-instead-free-in-cart',
  /**
  Boolean value that controls whether or not Table service mode is disabled globally.
  */
  ENABLE_TABLE_SERVICE = 'enable-table-service',
  /**
  Boolean value that controls whether or not set fixed delivery time (CH request).
  */
  ENABLE_OVERRIDE_DELIVERY_TIME = 'enable-override-delivery-time',
  /**
  Boolean value that enable or not the Loyalty auntoenrollment.
  */
  ENABLE_LOYALTY_AUTO_ENROLLMENT = 'enable-loyalty-auto-enrollment',
  /**
  Boolean value that forces the new loyalty modal for all stores
  */
  ENABLE_LOYALTY_REDEEM_IN_RESTURANT_SCREEN_IN_ALL_STORES = 'enable-loyalty-redeem-in-restaurant-screen-in-all-stores',
  /**
   * Number that controls the time to show/hide the Loyalty Link Card Reward Modal
   */
  LOYALTY_LINK_CARD_REWARD_TIMEOUT = 'loyalty-link-card-reward-timeout',
  /*
   * This flag controls if the Locker Pickup modal will poll every N seconds and display
   * an order locker code when ready.
   */
  ENABLE_ORDER_READY_LOCKER_CODE_MODAL = 'enable-order-ready-locker-code-modal',
  /*
   * Used for overwriting used country in the store location
   */
  ENABLE_OVERRIDE_COUNTRY_STORE_RESTRICTION = 'enable-override-country-store-restriction',

  /**
   * Whether to include the taxes in the total of the order (used mainly in EMEA where VAT is included in price)
   */
  ENABLE_CALCULATE_ORDER_TOTAL_WITH_TAXES = 'enable-calculate-order-total-with-taxes',

  /**
   * The flag controls if the zero priced order without payment is enabled
   */
  ENABLE_ZERO_PRICED_ORDER_WITHOUT_PAYMENT = 'enable-zero-priced-order-without-payment',

  /**
   * Skip refetching recent orders just after an order is committed.
   */
  ENABLE_SKIP_REFETCH_RECENT_ORDERS_ON_COMMIT = 'enable-skip-refetch-recent-orders-on-commit',

  /**
   * Whether to enable the app rating modal
   */
  ENABLE_APP_RATING_ON_ORDER_COMPLETED = 'enable-app-rating-on-order-completed',

  /**
   * Flag for toggling the loyalty tags on the Store Locator page
   */
  ENABLE_LOYALTY_TAGS_STORE_LOCATOR = 'enable-loyalty-tags-store-locator',

  /**
   * Flag for toggling the map fall back flow
   */
  ENABLE_MAP_FALLBACK = 'enable-map-fallback',

  /**
   * Flag for toggling pickup time modification
   */
  ENABLE_SCHEDULED_PICKUP_TIME_MODIFICATION = 'enable-scheduled-pickup-time-modification',

  /**
   * Flag for toggling time modification ahead
   */
  FIRE_SCHEDULED_TIME_MODIFICATION_AHEAD = 'fire-schedule-time-modification-ahead',

  /**
   * IAP22 related
   */
  ENABLE_IAP_PREFETCHING = 'enable-iap-prefetching',

  /**
   * Flag for toggling enable flow of save address in delivery
   */
  ENABLE_SAVE_DELIVERY_ADDRESS = 'enable-save-delivery-address',

  /**
   * Flag created to inform the amount of addresses that can be saved
   */
  AMOUNT_DELIVERY_ADDRESS_SAVED = 'amount-delivery-address-saved',

  /*
   * Flag for toggling the new style for map
   */
  ENABLE_CUSTOM_STYLE_MAP = 'enable-custom-style-map',

  /**
   * Flag for toggling the block by region
   */
  ENABLE_BLOCK_BY_REGION = 'enable-block-by-region',

  /**
   * Flag for toggling time schedule order before closed store
   */
  FIRE_TIME_SCHEDULE_ORDER_BEFORE_CLOSED_STORE = 'fire-time-schedule-order-before-closed-store',

  /**
   * Flag for toggling pickuptime component for drivethru
   */
  ENABLE_PICKUPTIME_DRIVETHRU = 'enable-pickup-time-drive-thru',

  /**
   * Flag for toggling settings component
   */
  ENABLE_ACCOUNT_SETTINGS = 'enable-account-settings',

  /**
   * Flag for toggling settings geolocation
   */
  ENABLE_SETTINGS_GEOLOCATION = 'enable-settings-geolocation',

  /**
   * Flag for toggling instagram integration
   */
  ENABLE_INSTAGRAM_INTEGRATION = 'enable-instagram-integration',

  /** Flag for toggling the "Remove Date of Birth" button from Account details page
   */
  ENABLE_REMOVE_DATE_OF_BIRTH_IN_ACCOUNT_PAGE = 'enable-remove-date-of-birth-in-account-page',

  /**
   * Flag for toggling the new UX for the store selector screen when choosing Delivery
   */
  ENABLE_DELIVERY_ADDRESS_ON_SAME_SCREEN = 'enable-delivery-address-on-same-screen',

  /**
   * This flag intends to avoid optional delivery address data being blank when it's mandatory for delivery partners.
   */
  VALIDATE_DELIVERY_ADDRESS_INFO = 'validate-delivery-address-info',

  /**
   * Flag for selecting the menu page dynamically
   */
  AB_TESTING_MENU_PAGE = 'ab-testing-menu-page',

  /**
   * Flag for enable paypal payment method in processor paycomet
   */
  ENABLE_PAYPAL_PAYCOMET = 'enable-paypal-paycomet',

  /**
   * Flag for enable save card in processor paycomet
   */
  ENABLE_SAVE_CARD_PAYCOMET = 'enable-save-card-paycomet',

  /**
   * Flag for enable sodexo credit card in processor paycomet
   */
  ENABLE_SODEXO_CREDIT_CARD_PAYCOMET = 'enable-sodexo-credit-card-paycomet',

  /**
   * Flag for enable sodexo voucher in processor paycomet
   */
  ENABLE_SODEXO_VOUCHER_PAYCOMET = 'enable-sodexo-voucher-paycomet',

  /**
   * Flag for enable cheque gourmet credit card in processor paycomet
   */
  ENABLE_CHEQUE_GOURMET_CREDIT_CARD_PAYCOMET = 'enable-cheque-gourmet-credit-card-paycomet',

  /**
   * Flag for enable cheque gourmet voucher in processor paycomet
   */
  ENABLE_CHEQUE_GOURMET_VOUCHER_PAYCOMET = 'enable-cheque-gourmet-voucher-paycomet',

  /**
   * Flag for enable ticket restaurant credit card in processor paycomet
   */
  ENABLE_TICKET_RESTAURANT_CARD_PAYCOMET = 'enable-ticket-restaurant-card-paycomet',

  /**
   * Flag for enable ticket restaurant voucher in processor paycomet
   */
  ENABLE_TICKET_RESTAURANT_VOUCHER_PAYCOMET = 'enable-ticket-restaurant-voucher-paycomet',

  /**
   * Enables adding offers to favorites
   */
  ENABLE_FAVORITE_OFFERS = 'enable-favorite-offers',

  /**
   * Flag for catering birthday feature
   */
  ENABLE_CATERING_BIRTHDAY = 'enable-catering-birthday',

  /**
   * Enables catering birthday on account menu
   */

  ENABLE_ACCOUNT_CATERING_BIRTHDAY = 'enable-account-catering-birthday',

  /**
   * Enables new redesign for Delivery flow
   */
  ENABLE_DELIVERY_GEOLOCATION_2 = 'enable-delivery-geolocation-2',

  /**
   * Enables social login
   */
  ENABLE_SOCIAL_LOGIN = 'enable-social-login',

  /**
   * Enables facebook social login option
   */
  ENABLE_SOCIAL_LOGIN_FACEBOOK = 'enable-social-login-facebook',

  /**
   * Enables google social login option
   */
  ENABLE_SOCIAL_LOGIN_GOOGLE = 'enable-social-login-google',

  /**
   * Enables apple social login option
   */
  ENABLE_SOCIAL_LOGIN_APPLE = 'enable-social-login-apple',

  /**
   * Enable to avoid sending detailed error information about auth user when sign in
   */
  ENABLE_USER_NOT_FOUND_MASK_ON_AUTH_FLOW = 'enable-user-not-found-mask-on-auth-flows',

  /**
   * Enable different menu once service mode is selected
   */
  NAVBAR_WITH_SERVICE_MODE_SELECTED = 'navbar-with-service-mode-selected',

  /**
   * Hide close button for (default) cookie banner
   */
  HIDE_COOKIE_BANNER_CLOSE_BUTTON = 'hide-cookie-banner-close-button',

  /**
   * Hide mobile ordering error message and order button
   */
  HIDE_MOBILE_ORDERING = 'hide-mobile-ordering',

  /**
   * Hide mobile ordering error message and order button
   */
  HIDE_NUTRITIONAL_INFORMATION = 'hide-nutritional-information',

  /**
   * Flag for hiding item quantity in cart page
   */
  HIDE_CART_ITEM_QUANTITY = 'hide-cart-item-quantity',

  ENABLE_ADDITIONAL_DETAILS_FOR_DELIVERY = 'temp-enable-additional-details-for-delivery',

  CHECKOUT_CASH_PRICE_LIMIT = 'checkout-cash-price-limit',

  /**
   * Flag for enabling links in the footer ImageWidget
   */
  ENABLE_CLICKABLE_IMAGES = 'enable-clickable-image-widget',

  /**
   * This flag toggles if we only show the street name and number in the Delivery input
   */
  ENABLE_DELIVERY_SHOW_ONLY_STREET_NAME_AND_NUMBER = 'enable-delivery-show-only-street-name-and-number',

  /**
   * This flag toggles the address details to be split into 3 fields
   */
  ENABLE_DELIVERY_SPLIT_ADDRESS_DETAILS = 'enable-delivery-split-address-details',

  /**
   * This flag toggles the store status infos that is highlighted in locations store info and store details
   */
  ENABLE_HIGHLIGHT_STORE_STATUS = 'highlight-store-status',

  /**
   * This flag toggles the store highlight breaking time slot
   */
  ENABLE_HIGHLIGHT_BREAKING_TIME_SLOT = 'highlight-breaking-time-slot',

  /**
   * Enable account menu brand icons
   */
  ENABLE_ACCOUNT_MENU_BRAND_ICONS = 'enable-account-menu-brand-icons',

  /**
   * Enable resize hero component
   */
  ENABLE_RESIZE_HERO_COMPONENT = 'enable-resize-hero-component',

  /**
   * PLK ES: Enable an error modal after restaurant selection when restaurant does not accept CC payments
   */
  IBERIA_WARNING_MODAL_NO_CREDIT_CARD_RESTAURANTS = 'iberia-warning-modal-no-credit-card-restaurants',

  /**
   * Switch Update Date Acceptance Agreement
   */
  SWITCH_UPDATE_DATE_ACCEPTANCE_AGREEMENT = 'switch-update-date-acceptance-agreement',

  /**
   * Enable Add On as Cart Item
   */
  ENABLE_ADD_ON_AS_CART_ITEM = 'enable-add-on-as-cart-item',

  /**
   * This flag toggles the feature that sort the payment-methods
   */
  ENABLE_SORT_PAYMENT_METHODS = 'enable-sort-payment-methods',

  /**
   * This flag toggles the logic to handle free addon items on cart
   */
  ENABLE_FREE_ADDON_ON_CART = 'enable-free-addon-on-cart',

  /**
   * This flag enables the address from pickup / delivery screens to be persisted when switching between them
   */
  ENABLE_PERSIST_ADDRESS_ON_STORE_SELECTOR = 'enable-persist-address-on-store-selector',

  /**
   * Boolean value that enable/disable the Flavor Flow for Recommendation.
   */
  ENABLE_FLAVOR_FLOW = 'enable-flavor-flow',

  /**
   * If enabled, it will hide the product from the recommendation list if this item is already on the cart.
   */
  FLAVOR_FLOW_HIDE_ITEMS_IN_CART = 'flavor-flow-hide-items-in-cart',

  /**
   * This is the number of items displayed.
   */
  FLAVOR_FLOW_NUMBER_ITEMS_DISPLAYED = 'flavor-flow-number-items-displayed',

  /**
   * Each product has 5 levels of hierarchy, here you can define the level used to split the items.
   */
  FLAVOR_FLOW_LEVEL_FILTERED = 'flavor-flow-level-filtered',

  /**
   * This flag toggles the url of check availability button
   */
  ENABLE_CHECK_AVAILABILITY_TO_DELIVERY = 'enable-check-availability-to-delivery',

  /**
   * This flag toggles the logic to handle qr code for refills
   */
  ENABLE_QRCODE_REFILL = 'enable-qrcode-refill',

  /**
   * This flag toggles the Loyalty Tiers functionality
   */
  ENABLE_LOYALTY_TIERS = 'enable-loyalty-tiers',
  /**
   * This flag toggles the logic to handle images by channels
   */
  ENABLE_IMAGES_BY_CHANNELS = 'enable-images-by-channels',
  /**
   * This flag toggles the logic to determine if a restaurant has its delivery closed at the moment.
   */
  ENABLE_DELIVERY_CLOSED_AT_MOMENT = 'enable-delivery-closed-at-moment',
  /**
  /**
   * This flag toggles the logic to determine if a restaurant has its delivery just paused.
   */
  ENABLE_DELIVERY_PAUSED = 'enable-delivery-paused',
  /**
   * This flag toggles the logic to change the to pickup time change page layout.
   */
  CHANGE_PICKUP_TIME_IN_ORDER_CONFIRMATION = 'change-pickup-time-in-order-confirmation',
  /**
   * This flag toggles the logic to show the bluecode pay button.
   */
  ENABLE_BLUECODE_PAY = 'enable-bluecode-pay',
  /**
   * This flag toggles the store locator visibility
   */
  SHOW_STORE_LOCATOR = 'show-store-locator',

  /**
   * Controls if call support link is displayed on the order confirmation page for delivery service mode
   */
  ENABLE_CALL_SUPPORT_LINK_FOR_DELIVERY = 'enable-call-support-link-for-delivery',

  ENABLE_MPARTICLE_SWITCH_CNAME = 'enable-mparticle-switch-cname',

  /**
   * Flag for DISABLE paycomet pre-auth payments
   */
  DISABLE_PAYCOMET_PRE_AUTH = 'disable-paycomet-pre-auth',

  /**
   * Enable blocking live updates
   */
  ENABLE_FORCE_BLOCKING_UPDATE = 'enable-force-blocking-update',

  /**
   * Flag for enable payment on delivery with card at home in processor paycomet
   */
  ENABLE_PAYMENT_ON_DELIVERY_CARD_PAYCOMET = 'enable-payment-on-delivery-card-paycomet',

  /**
   * Temporary flag for the development of the simplified static offers flow
   */
  ENABLE_IMPROVED_SIMPLIFIED_REDEEM_IN_RESTAURANT_STATIC_OFFERS = 'enable-improved-simplified-redeem-in-restaurant-static-offers',

  /**
   * Temporary flag for the development of deprecating isOfferBenefit toggle in Sanity resources
   */
  ENABLE_DEPRECATE_IS_OFFER_BENEFIT_TOGGLE = 'enable-deprecate-is-offer-benefit-toggle',

  /**
   * Flag for order premium options to be at first position in combo slot options with upsell simplified mode
   */
  ENABLE_PREMIUM_OPTIONS_AT_FIRST_POSITION = 'enable-premium-options-at-first-position',

  /**
   * This flag enables loyalty offer promotional code at checkout
   */
  ENABLE_LOYALTY_OFFER_PROMO_CODE_AT_CHECKOUT = 'enable-loyalty-offer-promo-code-at-checkout',

  /**
   * This flag enables loyalty rewards in the app (all other previous flags will be deprecated / removed)
   */
  ENABLE_LOYALTY_IN_APPS = 'enable-loyalty-in-apps',
  /**
   * This flag is used to identify what type of code to be displayed (Dynamic or Static)
   */
  LOYALTY_IDENTIFICATION_CODE_TYPE = 'loyalty-identification-code-type',

  WAIT_TIME_WARNING_OPTIONS = 'wait-time-warning-options',

  /**
   * This flag enables bizum as an option for the payment step.
   */
  ENABLE_BIZUM_PAYCOMET = 'enable-bizum-paycomet',

  /**
   * This flag enables cookiebot cookie banner
   */
  ENABLE_COOKIEBOT_COOKIE_BANNER = 'enable-cookiebot-cookie-banner',

  /**
   * Temporary flag used for rolling out the new Short Code widget design
   */
  ENABLE_LOYALTY_SHORT_CODE_UPDATED_DESIGN = 'enable-loyalty-short-code-updated-design',

  /**
   * This flag hide the courier information from delivery details
   */
  HIDE_COURIER_DETAILS = 'hide-courier-details',

  /**
   * This flag enables editing an offer that was added directly at the checkout page
   */
  ENABLE_EDIT_OFFER_ADDED_AT_CHECKOUT = 'enable-edit-offer-added-at-checkout',

  /**
   * When CheckoutLimitOverrideCents is set this flag hides the limit message in cart
   */
  HIDE_CHECKOUT_LIMIT_MESSAGE = 'hide-checkout-limit-message',

  /**
   * This flag enables to show the progress bar for free delivery fee at the checkout page
   */
  ENABLE_FREE_DELIVERY_FEE_PROGRESS_BAR = 'enable-free-delivery-fee-progress-bar',

  /**
   * This flag enables tiered delivery fees tier in checkout/cart screen
   */
  ENABLE_YOUR_CART_TIERED_DELIVERY_FEES = 'enable-your-cart-tiered-delivery-fees',

  /**
   * This flag enables Waylet Paycomet payment
   */
  ENABLE_WAYLET_PAYCOMET = 'enable-waylet-paycomet',

  /**
   * This flag selects the prefered sign up method
   */
  SIGN_UP_METHOD = 'sign-up-method',

  /**
   * This flag selects the prefered CDP provider
   */
  CDP_PROVIDER = 'cdp-provider',

  /**
   * This flag enables Apple Pay Paycomet payment
   */
  ENABLE_APPLE_PAY_PAYCOMET = 'enable-apple-pay-paycomet',

  /**
   * This flag enables the fees UI in cart, account order and receipt screens
   */
  ENABLE_FEES_UI = 'enable-fees-ui',

  /**
   * This flag enables the OTP Timer in the OTP screen
   */
  ENABLE_OTP_TIMER = 'enable-otp-timer',

  /**
   * Rollout flag to enable using language codes as part of URLs.
   * To be removed as part of ICFE-797 once the feature is live for all markets.
   */
  ROLLOUT_ENABLE_LANGUAGE_CODE_IN_URLS = 'rollout-enable-language-code-in-urls',

  /**
   * This flag specifies where to display the upsell modal: on the checkout screen or on the product details screen.
   */
  UPSELL_MODAL = 'upsell-modal',

  /**
   * Remove/hidden service fees by restaurants.
   * This is a temporary flag until the migration of airtouch to iberia restaurants
   */
  ENABLE_HIDE_SERVICE_FEES_BY_RESTAURANT = 'enable-hide-service-fees-by-restaurant',

  /**
   * Enable Cash Limitation
   * This flag enables the cash limitation rules to disable cash payment
   * on certain occasions in the delivery mode
   */
  ENABLE_CASH_LIMITATION = 'enable-cash-limitation',

  /**
   * This flag changes the flow of how Offers and Rewards are redeemed in our app.
   * It adds a floating cart button when adding offers/rewards to the In-restaurant redemption flow.
   * The users will no longer get redirected to the QR code page.
   */
  ENABLE_LOYALTY_FLOATING_CART_BUTTON = 'enable-loyalty-floating-cart-button',

  /**
   * Enables the Quick Sign-in Feature on the Sign-In page:
   * TODO: Temp. flag, must be deleted once we finish the Iberia roll-out and we remove
   * the related code from the app.
   */
  ROLLOUT_IBERIA_ENABLE_QUICK_SIGN_IN_MIGRATION = 'enable-iberia-rollout-quick-singin-migration',

  /**
   * This flag enables the Unified Sign Up/ Sign In Process
   */
  ENABLE_UNIFIED_SIGN_IN = 'enable-unified-sign-in',

  /**
   * This flag adds a new button in the header for accessing the My Code page directly
   */
  ENABLE_LOYALTY_QR_CODE_HEADER_BUTTON = 'enable-loyalty-qr-code-header-button',

  /**
   * Temporary feature flag to show support policy cover on the support page.
   */
  ENABLE_SUPPORT_POLICY_COVER_TO_CONTACT = 'enable-support-policy-cover-to-contact',

  /*
   * This flag enables the 3rd experiment for the Loyalty Page
   */

  ENABLE_REDESIGN_LOYALTY_QR_CODE_PAGE = 'enable-redesign-loyalty-qr-code-page',
  /**
   * This flag Enables Remove Cardholder Name Pre-filled.
   */
  ENABLE_REMOVE_CARDHOLDER_NAME_PRE_FILLED = 'enable-remove-cardholder-name-pre-filled',

  /**
   * This flag Enables Processing Payment Splash Screen.
   */
  ENABLE_PROCESSING_PAYMENT_SPLASH_SCREEN = 'enable-processing-payment-splash-screen',

  /**
   * This flag enables the Unified Social Sign Up/ Sign In Process
   */
  ENABLE_UNIFIED_SOCIAL_SIGN_IN = 'enable-unified-social-sign-in',

  /**
   * This flag retrieve all accepted cards types.
   */
  ENABLE_VISUAL_INFO_ACCEPTED_CARDS = 'accepted-cards-types',
}

/**
 * Secondly, update the appropriate union based on the type
 * your new flag should return
 */
export type NumericFlags =
  | LaunchDarklyFlag.CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH
  | LaunchDarklyFlag.FIRE_ORDER_AHEAD
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT
  | LaunchDarklyFlag.CHECKOUT_CASH_PRICE_LIMIT
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_LIMIT
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM
  | LaunchDarklyFlag.DELIVERY_RADIUS_IN_MILES
  | LaunchDarklyFlag.DELIVERY_BANNER_POLLING
  | LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE
  | LaunchDarklyFlag.DATADOG_RUM_REPLAY_SAMPLE_RATE
  | LaunchDarklyFlag.RESET_CART_RESET_STORE_TIMEOUT
  | LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS
  | LaunchDarklyFlag.CHEF_CART_UPSELL_ITEM_COUNT
  | LaunchDarklyFlag.DISCOUNTED_DELIVERY_THRESHOLD_CENTS
  | LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR
  | LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE
  | LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE_MPARTICLE
  | LaunchDarklyFlag.LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS
  | LaunchDarklyFlag.ORDER_CART_VERSION
  | LaunchDarklyFlag.RESET_CART_TIMEOUT_HRS
  | LaunchDarklyFlag.LOYALTY_LINK_CARD_REWARD_TIMEOUT
  | LaunchDarklyFlag.AMOUNT_DELIVERY_ADDRESS_SAVED
  | LaunchDarklyFlag.FIRE_SCHEDULED_TIME_MODIFICATION_AHEAD
  | LaunchDarklyFlag.FIRE_TIME_SCHEDULE_ORDER_BEFORE_CLOSED_STORE
  | LaunchDarklyFlag.FLAVOR_FLOW_NUMBER_ITEMS_DISPLAYED;

export type StringFlags =
  | LaunchDarklyFlag.AB_TESTING_HOME_PAGE
  | LaunchDarklyFlag.AB_TESTING_MENU_PAGE
  | LaunchDarklyFlag.AB_TESTING_FEATURE_NAVIGATION
  | LaunchDarklyFlag.DEFAULT_SERVICE_MODE_IN_STORE
  | LaunchDarklyFlag.ENABLE_OFFER_REDEMPTION_METHODS
  | LaunchDarklyFlag.MOBILE_CART_UPSELL_LOCATION
  | LaunchDarklyFlag.CHEF_RECOMMENDATION_ENGINE_2
  | LaunchDarklyFlag.ENABLE_FORCED_NATIVE_APP_UPDATE
  | LaunchDarklyFlag.GOOGLE_PAY_ALLOWED_PAYMENT_METHODS
  | LaunchDarklyFlag.FLAVOR_FLOW_LEVEL_FILTERED
  | LaunchDarklyFlag.CHECKOUTDOTCOM_RISK;

export type VariationFlags =
  | LaunchDarklyFlag.ENABLE_ACCOUNT_SECTIONS_VARIATIONS
  | LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  | LaunchDarklyFlag.ENABLE_PREPAID_PAYMENT_METHODS
  | LaunchDarklyFlag.HIDE_ITEMS_FROM_NUTRITION_INFO
  | LaunchDarklyFlag.HIDE_SERVICE_MODE_OPTION
  | LaunchDarklyFlag.MY_ACCOUNT_FIELDS_VARIATIONS
  | LaunchDarklyFlag.ORDER_LOCATOR_TABS
  | LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS
  | LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS
  | LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS
  | LaunchDarklyFlag.STORE_CARD_BUTTON_OPTIONS
  | LaunchDarklyFlag.TIMED_FIRE_OPTIONS
  | LaunchDarklyFlag.IBERIA_WARNING_MODAL_NO_CREDIT_CARD_RESTAURANTS
  | LaunchDarklyFlag.ENABLE_OVERRIDE_COUNTRY_STORE_RESTRICTION
  | LaunchDarklyFlag.SWITCH_UPDATE_DATE_ACCEPTANCE_AGREEMENT
  | LaunchDarklyFlag.DISABLE_PAYCOMET_PRE_AUTH
  | LaunchDarklyFlag.LOYALTY_QR_CODE_SIZE_COLOR_VARIATIONS
  | LaunchDarklyFlag.LOYALTY_IDENTIFICATION_CODE_TYPE
  | LaunchDarklyFlag.SIGN_UP_METHOD
  | LaunchDarklyFlag.CDP_PROVIDER
  | LaunchDarklyFlag.WAIT_TIME_WARNING_OPTIONS
  | LaunchDarklyFlag.UPSELL_MODAL
  | LaunchDarklyFlag.ENABLE_VISUAL_INFO_ACCEPTED_CARDS;

export type BooleanFlags = Exclude<LaunchDarklyFlag, NumericFlags | StringFlags | VariationFlags>;

export type FlagType<F extends LaunchDarklyFlag> = F extends NumericFlags
  ? number
  : F extends StringFlags
    ? string
    : F extends BooleanFlags
      ? boolean
      : F extends VariationFlags
        ? unknown
        : never;
